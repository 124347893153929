import { useTranslations } from 'next-intl';
import Divider from './Divider';

export default function ContactSection() {
  const t = useTranslations('common');
  return (
    <div className="order-last">
      <section>
        <h3 className="text-base font-medium text-greyscale-offwhite mb-2 tracking-tight">
          {t('Footer.section.contact-us')}
        </h3>

        <ul className="m-0 p-0 space-y-4 sm:space-y-0 lg:space-y-4 sm:grid sm:grid-cols-2 md:grid-cols-3 lg:block">
          <li>
            <h4 className="text-sm font-medium text-greyscale-separator mb-1 tracking-tight">
              {t('Footer.section.customer-care')}
            </h4>
            <a
              className="text-sm hover:underline text-greyscale-placeholder hover:text-greyscale-pink"
              href="mailto:assistenza@winelivery.com"
            >
              assistenza@winelivery.com
            </a>
          </li>

          <li>
            <h4 className="text-sm font-medium text-greyscale-separator mb-1 tracking-tight">
              {t('Footer.section.press')}
            </h4>
            <a
              className="text-sm hover:underline text-greyscale-placeholder hover:text-greyscale-pink"
              href="mailto:press@winelivery.com"
            >
              press@winelivery.com
            </a>
          </li>

          <li>
            <h4 className="text-sm font-medium text-greyscale-separator mb-2 tracking-tight">
              {t('Footer.section.follow-us')}
            </h4>
            <ul className="flex p-0 m-0 space-x-4">
              <li>
                <a
                  className="no-underline inline-block rounded-full w-10 h-10 border border-[#ffffff1f] transition-colors duration-300 hover:bg-[#ffffff0f] text-[#ffffff99] leading-[38px] text-center align-middle"
                  target="_blank"
                  href="https://www.facebook.com/winelivery/"
                  rel="nofollow"
                >
                  <i className="socicon-facebook" />
                </a>
              </li>
              <li>
                <a
                  className="no-underline inline-block rounded-full w-10 h-10 border border-[#ffffff1f] transition-colors duration-300 hover:bg-[#ffffff0f] text-[#ffffff99] leading-[38px] text-center align-middle"
                  target="_blank"
                  href="https://www.instagram.com/winelivery/"
                  rel="nofollow"
                >
                  <i className="socicon-instagram" />
                </a>
              </li>
              <li>
                <a
                  className="no-underline inline-block rounded-full w-10 h-10 border border-[#ffffff1f] transition-colors duration-300 hover:bg-[#ffffff0f] text-[#ffffff99] leading-[38px] text-center align-middle"
                  target="_blank"
                  href="https://www.youtube.com/channel/UCyZEX3vU17uM8WB_ORo6yUg"
                  rel="nofollow"
                >
                  <i className="socicon-youtube" />
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </section>
      <Divider />
    </div>
  );
}
