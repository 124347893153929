import Divider from './Divider';
import DownloadAppButton from 'src/components/design-system/DownloadAppButton';
import { useTranslations } from 'next-intl';

export default function DownloadSection() {
  const t = useTranslations('common');
  return (
    <div className="order-last">
      <section className="mb-5 lg:mb-auto">
        <h3 className="text-base font-medium text-greyscale-offwhite mb-2 tracking-tight">
          {t('Footer.section.bring-us-with-you')}
        </h3>
        <div className="flex">
          <DownloadAppButton desktopButtonTheme="white" />
        </div>
      </section>
      <Divider />
    </div>
  );
}
