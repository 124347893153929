import { useTranslations } from 'next-intl';
import FooterLink from './FooterLink';

export default function BottomLinks() {
  const t = useTranslations('common');
  const className =
    'text-sm text-greyscale-input hover:text-greyscale-pink';
  return (
    <div className="space-x-4 flex flex-wrap justify-center">
      <div>
        <FooterLink className={className} internal link={{ href: `/terms` }}>
          {t('Footer.link.terms')}
        </FooterLink>
      </div>
      <div>
        <FooterLink className={className} internal link={{ href: `/privacy` }}>
          {t('Footer.link.privacy')}
        </FooterLink>
      </div>
      <div>
        <FooterLink
          className={className}
          link={{
            href: 'https://www.iubenda.com/privacy-policy/47619544/cookie-policy',
          }}
        >
          {t('Footer.link.cookies')}
        </FooterLink>
      </div>
      <div>
        <FooterLink
          className={`${className} iubenda-cs-preferences-link`}
          link={{
            href: '#',
          }}
        >
          {t('Footer.link.privacy-preferences')}
        </FooterLink>
      </div>
    </div>
  );
}
