import { PropsWithChildren } from 'react';

interface Props extends PropsWithChildren {
  title: string;
}

export default function SectionContainer(props: Props) {
  return (
    <section>
      <h3 className="text-base font-medium text-greyscale-offwhite mb-2 tracking-tight">
        {props.title}
      </h3>
      <ul className="m-0 p-0 space-y-1 sm:grid sm:grid-cols-2 md:grid-cols-3 lg:block">
        {props.children}
      </ul>
    </section>
  );
}
