'use client';

// import {datadogRum} from '@datadog/browser-rum';

// datadogRum.init({
//   applicationId: '885b6459-3e0c-435f-be09-cffc224a4aaa',
//   clientToken: 'pubffcc9dfb3ef073d39894bc1597799530',
//   site: 'datadoghq.eu',
//   service: 'www.winelivery.com',
//   env: 'production',
//   sessionSampleRate: 100,
//   sessionReplaySampleRate: 0,
//   trackUserInteractions: true,
//   trackResources: true,
//   trackLongTasks: true,
//   defaultPrivacyLevel: 'mask-user-input',
//   allowedTracingUrls: [
//     {match: 'https://api.eu1..winelivery.com/', propagatorTypes: ['datadog']},
//     {match: 'https://api.winelivery.com/', propagatorTypes: ['datadog']},
//     {match: 'https://apis.winelivery.com/', propagatorTypes: ['datadog']},
//     {
//       match: 'https://api.eu1.winelivery.com/',
//       propagatorTypes: ['datadog'],
//     },
//   ],
// });

export default function DatadogInit() {
  // Render nothing - this component is only included so that the init code
  // above will run client-side
  return null;
}
